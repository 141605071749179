


































































































































































































































































































































































import CatalogFacetsComponent from "./CatalogFacetsComponent";
export default CatalogFacetsComponent;
